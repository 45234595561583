'use client';

import Invalid from './_pages/Invalid/Invalid';
import { Config } from './_config/schema';
import { withConfig } from './_hocs/withConfig';

function Home({ config }: { config: Config }) {
  if (!config) return null;

  return <Invalid config={config} />;
}

export default withConfig(Home);
