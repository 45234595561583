import { Card, CardHeader, Headline, Text } from '@vereign/ui';
import { useTranslation } from 'next-export-i18n';
import Link from '@/app/_comps/Link/Link';
import { Config } from '@/app/_config/schema';

const Invalid = ({ config }: { config: Config }) => {
  const { t } = useTranslation();
  console.log(config);
  return (
    <Card className="flex max-w-screen-xl flex-row">
      <CardHeader className="flex flex-col items-center gap-8 lg:flex-row lg:p-32">
        <img
          src="/graphics/error-phone.png"
          alt="phone with error"
          className="hidden w-1/3 lg:block"
        />
        <div className="flex flex-1 flex-col gap-8">
          <div className="flex flex-row gap-4">
            <img src="/graphics/warning-red.svg" alt="warning icon" />
            <Headline as="h2" className="text-3xl font-bold lg:text-4xl">
              {t('pages.invalidPage.title')}
            </Headline>
          </div>
          <Text as="p" className="font-bold">
            {t('pages.invalidPage.bold')}
          </Text>
          <Text as="p">{t('pages.invalidPage.description')}</Text>

          <Text as="p">
            {t('pages.invalidPage.paragraph')}
            {` `}
            <Link className="font-bold" href={config.general.support} external>
              {t('common.links.hinSupport')}
            </Link>
          </Text>

          <div className="flex flex-row justify-center lg:hidden">
            <img
              src="/graphics/error-phone.png"
              alt="phone with error"
              className="h-64 w-64"
            />
          </div>
        </div>
      </CardHeader>
    </Card>
  );
};

export default Invalid;
